import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    createCarDescriptionFields,
    DealerLayout,
    TabsLayout,
} from "@modules/dealer";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import { CarModelType } from "@modules/shared/fields";
import {
    getNavigationPages,
    validateBusinessRules,
} from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { appState } from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";

const DealerCarDescriptionPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState(appState);
    const { t } = useTranslation();
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const { businessRules, getWtcPage } = useWtc(allPages);
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });
    const carDescriptionFields = useMemo(
        () =>
            createCarDescriptionFields(
                appData.DealerBeforeWeStart?.lightLoadVehicle
                    ? CarModelType.LightTrucks
                    : CarModelType.Cars,
            ),
        [appData.DealerBeforeWeStart?.lightLoadVehicle],
    );

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            DealerCarDescription: appData.DealerCarDescription,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFormState = (formValues: FormValues): void => {
        setAppData({
            ...appData,
            DealerCarDescription: formValues.DealerCarDescription,
        });
    };

    const handleSubmit = (values: FormValues): void => {
        updateFormState(values);
        const wtcPage = getWtcPage(
            validateBusinessRules(businessRules, appData).action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextPage}
                        currentPage={page}
                        language={language}
                    />
                );
            }
        },
        [language, nextPage, page, formProps],
    );

    return (
        <DealerLayout
            title={t("dealer.dealercardescription.title")}
            allPages={allPages}
            page={page}
            language={language}
        >
            <TabsLayout
                title={t("dealer.tabs.title")}
                description={t("dealer.tabs.description")}
                language={language}
                allPages={allPages}
                activeTabName={page.name}
            >
                {({ tabsStatus }) => {
                    return (
                        <>
                            <div className="intro">
                                <BalHeading level="h1" visualLevel="h3">
                                    {t("dealer.dealercardescription.title")}
                                </BalHeading>
                                <BalText>
                                    {t("dealer.dealercardescription.intro")}
                                </BalText>
                            </div>
                            <Form
                                scrollToFieldError
                                formContext={{
                                    t,
                                    nameSpace: PageNames.DealerCarDescription,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={handleSubmit}
                                onChange={handleOnChange}
                                fields={carDescriptionFields}
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus)
                                }
                                enableReinitialize
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPage={nextPage}
                                    nextColor="info"
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </DealerLayout>
    );
};

export default DealerCarDescriptionPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
